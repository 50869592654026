import React, { Component } from 'react';
import '../App.css';
import $ from "jquery";
import icon from '../assets/frog-leg-print.png';
import icon2 from '../assets/right-arrow (1).png';

window.addEventListener("scroll", reveal);

$(window).on('scroll', function () {
    function isScrollIntoView(elem, index) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(window).height() * .5;
        if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            $(elem).addClass('active');
        }
        if (!(elemBottom <= docViewBottom)) {
            $(elem).removeClass('active');
        }
    }
    var timeline = $('.vertical-scrollable-timeline li');
    Array.from(timeline).forEach(isScrollIntoView);
});
function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 15;

        if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
        } else {
            reveals[i].classList.remove("active");
        }
    }
}

window.addEventListener("scroll", reveal);

$(window).on('scroll', function () {
    function isScrollIntoView(elem, index) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(window).height() * .5;
        if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            $(elem).addClass('active');
        }
        if (!(elemBottom <= docViewBottom)) {
            $(elem).removeClass('active');
        }
    }
    var timeline = $('.vertical-scrollable-timeline li');
    Array.from(timeline).forEach(isScrollIntoView);
});


class Roadmap extends Component {

    async componentDidMount() {

    }

    render() {
        return (
            <div class="boxWrap2Rm">
            <div class="roadmapBlur">

                <div class="title"><span className='rmColor'>Roadmap</span></div>

                <div class="timeline-container">
                    <ul class="vertical-scrollable-timeline">
                        <li>
                            <h2>Phase 1: <span class="smallL">Concept and Plan</span></h2>
                            <p><img class="rmNote" src={icon2} />Refine the concept of the “Sample” NFT Collection, combining French Bulldogs and travel destinations.</p>
                            <p><img class="rmNote" src={icon2} />Develop a clear vision for the collection’s themes, styles, and artistic direction.</p>
                            <p><img class="rmNote" src={icon2} /> Artistic development and creation of 10k NFT collection.</p>
                            <p><img class="rmNote" src={icon2} />Smart contract development and testing.</p>
                            <p><img class="rmNote" src={icon2} />Website and social media launch</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={icon} />

                            </div>
                        </li>
                        <li>
                            <h2>Phase 2: <span class="smallL">Community and Marketing</span></h2>

                            <p><img class="rmNote" src={icon2} />Initiate community-building efforts, including contests, giveaways, and interactive discussions related to French Bulldogs, travel, and NFTs.</p>
                            <p><img class="rmNote" src={icon2} />Social media marketing campaign.</p>
                            <p><img class="rmNote" src={icon2} />Set a launch date for the “Frenchie Royale” NFT collection.</p>
                            <p><img class="rmNote" src={icon2} />Establish partnerships with NFT marketplaces.</p>

                            <div class="icon-holder">
                                <img class="footPrint2" src={icon} />
                            </div>
                        </li>
                        <li>
                            <h2>Phase 3: <span class="smallL">Launch and Growth</span></h2>

                            <p><img class="rmNote" src={icon2} />Whitelist and public minting.</p>
                            <p><img class="rmNote" src={icon2} />Activate THE EMBASSY granting collectors access.</p>
                            <p><img class="rmNote" src={icon2} />Gather feedback and implement improvements based on user experiences.</p>
                            <p><img class="rmNote" src={icon2} />Explore opportunities for collaborations and partnerships.</p>

                             <div class="icon-holder">
                                <img class="footPrint2" src={icon} />
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
            </div>
        )
    }
}

export default Roadmap;
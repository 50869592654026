import React, { Component } from 'react';

class Banners extends Component {

    render() {
        return (

            <div class="bannerMain">
                
            <div class="banner2">
                <p>Mint Your Masterpiece. <br/> Own the Magic</p>
            </div>

            <div class="banner1">
                <p>Stake Your Assets in <br/> Digital Art Evolution</p>
            </div>

            </div>

        );
    }
}

export default Banners;        
import React, { Component } from 'react';
import $ from "jquery";

class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqBg">

        <div class="accordin reveal">

          <div className='title'><span className='faqC'>FAQ</span></div>

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>How we Roll?</summary>
            <div class="faq__content">
              <p>Transform: Wrap your yawn-inducing erc20 tokens into NFTs that scream rebellion. It's not just an upgrade; it's a revolution.</p>
              <p>Earn: Stack ETH rewards whenever you wrap your erc20 $HYBRID tokens into your dope erc721 $HYBRID NFTs!  Tax reflections don't go to erc20 holders, and only go to our most loyal rebels who wrap into the NFTs.  Note - you can unwrap at any time, should you decide to be a jeety bitch.</p>
              <p>Play: Play with your NFTs however you damn please. Trade, hold, show off – your playground, your rules.</p>
            </div>
          </details>
          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>Why HYBRIDS Kicks erc404's Ass?</summary>
            <div class="faq__content">
              <p></p>
              <p>Next-Level Innovation: While erc404's are busy playing catch-up, the ERC-20 to ERC-721 tech we use is about to blow minds and break norms.</p>
              <p>Earn Like a Boss: Our ETH rewards are the real deal, fit for the crypto chad you are.</p>
              <p>Rebel With Us: This ain't a community; it's a brotherhood. We're the outcasts, the misfits, the rebels. And we're damn proud of it.
                Roadmap to Chaos</p>

            </div>
          </details>
          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>Our plan? More like a scheme to turn the crypto world upside down:</summary>
            <div class="faq__content">
              <p>Rally the Outlaws: We're calling all crypto renegades. If you've got a bone to pick with the status quo, you belong with us.</p>
              <p>Show Off: Let the crypto world know what they're missing. Flaunt your NFTs AND your ETH rewards and make 'em weep.</p>
              <p>Tech Rebellion: The tech's not just groundbreaking; it's earth-shattering, making erc404 look like yesterday's news.
                Join the Anarchy
              </p>
              <p>Jump into the fray with HYBRIDS. Connect with us on Telegram and Twitter/X. We're not just building a project; we're starting a revolution.</p>
            </div>
          </details>

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>Decentralized Insurgency</summary>
            <div class="faq__content">
              <p>HYBRIDS isn't just decentralized; it's a declaration of independence from the ordinary, where every holder is a ruler in their own right.</p>
            </div>
          </details>

        </div>
      </div>


    )
  }
}

export default FAQ;


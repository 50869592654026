import React, { Component } from 'react';
import '../carousel.css';
import d1 from '../assets/1-min.png';
import d2 from '../assets/1_-min.png';
import d3 from '../assets/3-min.png';
import d4 from '../assets/3_-min.png';
import d5 from '../assets/4-min.png';
import d6 from '../assets/4_-min.png';
import d7 from '../assets/5-min.png';
import d8 from '../assets/5_-min.png';
import d9 from '../assets/6-min.png';
import d10 from '../assets/6_-min.png';
import d11 from '../assets/7-min.png';
import d12 from '../assets/7_-min.png';
import d13 from '../assets/8-min.png';
import d14 from '../assets/8_-min.png';
import d15 from '../assets/9-min.png';
import d16 from '../assets/9_-min.png';
import d17 from '../assets/10-min.png';
import d18 from '../assets/10_-min.png';
import d19 from '../assets/11-min.png';
import d20 from '../assets/11_-min.png';
import d21 from '../assets/12-min.png';
import d22 from '../assets/12_-min.png';
import d23 from '../assets/13-min.png';
import d24 from '../assets/13_-min.png';
import d25 from '../assets/14-min.png';
import d26 from '../assets/14_-min.png';
import d27 from '../assets/15-min.png';
import d28 from '../assets/15_-min.png';
import d29 from '../assets/16-min.png';
import d30 from '../assets/16_-min.png';
import d31 from '../assets/17-min.png';
import d32 from '../assets/17_-min.png';
import d33 from '../assets/18-min.png';
import d34 from '../assets/18_-min.png';
import d35 from '../assets/19-min.png';
import d36 from '../assets/19_-min.png';
import d37 from '../assets/20-min.png';
import d38 from '../assets/20_-min.png';
import d39 from '../assets/21-min.png';
import d40 from '../assets/21_-min.png';
import d41 from '../assets/22-min.png';
import d42 from '../assets/22_-min.png';
import d43 from '../assets/23-min.png';
import d44 from '../assets/23_-min.png';
import d45 from '../assets/24-min.png';
import d46 from '../assets/24_-min.png';
 
class Carousel extends Component { 

    render() {
        return (

            <div class="boxWrapCMob">
 
 <div class="slider">
										<div class="slide-track2">
											<div class="slide">
												<img id="img1" class="carouselIMG" src={d1} alt="d5" />
											</div>
											<div class="slide">
												<img id="img2" class="carouselIMG" src={d2} alt="d5" />
											</div>
											<div class="slide">
												<img id="img3" class="carouselIMG" src={d3} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d4} alt="d4" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d5} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d6} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d7} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d8} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d9} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d10} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d11} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d12} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d13} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d14} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d15} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d16} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d17} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d18} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d19} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d20} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d21} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d22} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d23} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d24} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d25} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d26} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d27} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d28} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d29} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d30} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d31} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d32} alt="d5" />
											</div><div class="slide">
												<img class="carouselIMG" src={d33} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d34} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d35} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d36} alt="d5" />
											</div><div class="slide">
												<img class="carouselIMG" src={d37} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d38} alt="d5" />
											</div><div class="slide">
												<img class="carouselIMG" src={d39} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d40} alt="d5" />
											</div><div class="slide">
												<img class="carouselIMG" src={d41} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d42} alt="d5" />
											</div><div class="slide">
												<img class="carouselIMG" src={d43} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d44} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d45} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d46} alt="d5" />
											</div>
										</div>
									</div>
              
            </div>
        );
    }
}

export default Carousel;        
import React, { Component } from 'react';
import logo from '../assets/Peyoti_Pepe_logo.png';
import x from '../assets/x.png';
import os from '../assets/os.png';

class Footer extends Component {

    render() {
        return (

            <footer>
                <div class="footer-container">
                    <div class="footer-logo">
                        <img src={logo} alt="Logo" />
                    </div>
                    <div class="footer-nav">
                        <a href="#">Story</a>
                        <a href="#">Roadmap</a>
                        <a href="#">FAQ</a>
                        <a href="#">Team</a>
                    </div>
                    <div class="footer-socials">
                        <img src={x}/>
                        <img src={os}/>
                    </div>
                    <div class="footer-credit">
                        <p>© 2024 Peyote Pepe. All rights reserved.</p>
                    </div>
                </div>
            </footer>


        );
    }
}

export default Footer;        
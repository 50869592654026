import React, { Component } from 'react';
import mem1 from '../assets/3-min.png';
import mem2 from '../assets/6-min.png';
import mem3 from '../assets/4-min.png';

class Team extends Component {

    render() {
        return (

            <div className='teamMain'>
                <div class="teamBlur">

                    <div className='title'>Our Team</div>

                    <div className='team'>
                        <div className='member'>
                            <img src={mem1} />
                            <div className='name'>Peter S.</div>
                            <div className='position'>Founder</div>
                        </div>

                        <div className='member'>
                            <img src={mem2} />
                            <div className='name'>Noah Cyrus</div>
                            <div className='position'>Manager</div>
                        </div>

                        <div className='member'>
                            <img src={mem3} />
                            <div className='name'>Oscar Isaac</div>
                            <div className='position'>Marketing</div>
                        </div>

                    </div>

                </div>
            </div>

        );
    }
}

export default Team;        
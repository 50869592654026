/* eslint-disable no-undef */
import './carousel.css';
import './App.css';
import React, { useEffect, useMemo, useState } from 'react';
import os from './assets/os.png';
import twitter from './assets/x.png';
import logo from './assets/1-min.png';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork, useWaitForTransaction } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther, webSocket } from 'viem';
import Countdown from 'react-countdown';
import { bsc } from 'wagmi/chains'
import $ from 'jquery';
import Carousel from './Components/carousel';
import d1 from './assets/1-min.png';
import d2 from './assets/1_-min.png';
import d3 from './assets/3-min.png';
import d4 from './assets/3_-min.png';
import d5 from './assets/4-min.png';
import d6 from './assets/4_-min.png';
import d7 from './assets/5-min.png';
import d8 from './assets/5_-min.png';
import d9 from './assets/6-min.png';
import d10 from './assets/6_-min.png';
import d11 from './assets/7-min.png';
import d12 from './assets/7_-min.png';
import d13 from './assets/8-min.png';
import d14 from './assets/8_-min.png';
import d15 from './assets/9-min.png';
import d16 from './assets/9_-min.png';
import d17 from './assets/10-min.png';
import d18 from './assets/10_-min.png';
import d19 from './assets/11-min.png';
import d20 from './assets/11_-min.png';
import d21 from './assets/12-min.png';
import d22 from './assets/12_-min.png';
import d23 from './assets/13-min.png';
import d24 from './assets/13_-min.png';
import d25 from './assets/14-min.png';
import d26 from './assets/14_-min.png';
import d27 from './assets/15-min.png';
import d28 from './assets/15_-min.png';
import d29 from './assets/16-min.png';
import d30 from './assets/16_-min.png';
import d31 from './assets/17-min.png';
import d32 from './assets/17_-min.png';
import d33 from './assets/18-min.png';
import d34 from './assets/18_-min.png';
import d35 from './assets/19-min.png';
import d36 from './assets/19_-min.png';
import d37 from './assets/20-min.png';
import d38 from './assets/20_-min.png';
import d39 from './assets/21-min.png';
import d40 from './assets/21_-min.png';
import d41 from './assets/22-min.png';
import d42 from './assets/22_-min.png';
import d43 from './assets/23-min.png';
import d44 from './assets/23_-min.png';
import d45 from './assets/24-min.png';
import d46 from './assets/24_-min.png';
 

import logoImg from './assets/Peyoti_Pepe_logo.png';
import Ticker from './ticker';
import vault from './assets/vault.png';
import Story from './Components/story';
import Roadmap from './Components/roadmap';
import FAQ from './Components/faq';
import Team from './Components/team';
import Footer from './Components/footer';
import Banners from './Components/banners';
import up from './assets/up-arrow.png';
import navbar from './assets/navigation-bar.png';
import wrong from './assets/wrong.png';

const ops = () => {
	window.open("#");
}

const tweet = () => {
	window.open("#");
}

const colorArtDapp = () => {
	window.open("https://colorart.pages.dev/");
}

const lineArtDapp = () => {
	window.open("https://lineart.pages.dev/");
}

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 2500);
});

const App = () => {

	const [showButton, setShowButton] = useState(false);
	const [_navbarOpen, set_navbarOpen] = useState(0)

	async function closeNav() {
		set_navbarOpen(0);
	}

	async function navbarOpen() {
		set_navbarOpen(1);
	}

	useEffect(() => {

		console.log("window.scrollY : " + window.scrollY);
		console.log("window.innerHeight : " + window.innerHeight);

		function handleScroll() {

			if (window.scrollY > window.innerHeight) {
				setShowButton(true);
			} else {
				setShowButton(false);
			}

		}

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);

		// Function to check the top position of the .carouselIMG element
		function checkTopPosition() {
			var classAdded = "false";
			var img = document.querySelector('#img1');
			var imgTop = img.getBoundingClientRect().top;
			console.log('Top position of carouselIMG:', imgTop);

			if (imgTop < -1900) {
				if (classAdded == "false") {
					img.classList.add('scaled'); // Add the class to scale up
					console.log("Add");
					classAdded = "true";
				}

				console.log(classAdded);

			} else {
				img.classList.remove('scaled'); // Remove the class if not in the condition
				classAdded = "false";
				console.log(classAdded);
			}
			// You can add your logic here to react to changes in the top position
		}


		// eslint-disable-next-line no-use-before-define
	}, []);

	return (
		<div class="allWrap">
			<div class="light">

				<div class="page-loader">
					<div class="parent-container">
						<div class="loader"></div>
						<div class="loadTxt">LOADING</div>
					</div>
				</div>

				<div class="cont">

					<Element name="up">

						<div class="introduction">
							{_navbarOpen < 1 ?
								(<div class="miniBarMain">
									<div className='loginDiv'><img className='logoPic' src={logo} /></div>

									<div className='loginDiv'><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
								</div>) :
								(<div class="miniBarMain">
									<img className='logoPic' src={logo} />

									<div className='loginDiv'><img class="navbar" onClick={closeNav} src={wrong} /></div>

								</div>)}

							{_navbarOpen > 0 ?
								(<div class="littleNav">
									<div class="connect">
										<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}>Story</Link></div>
										<div ><Link activeClass="" id="fontSize" to="rm" spy={true} smooth={true} duration={550}>Roadmap</Link></div>
										<div ><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>
										<div ><Link activeClass="" id="fontSize" to="team" spy={true} smooth={true} duration={550}>Team</Link></div>
									</div>


									<div class="socialIcon">
										<img src={twitter} />
										<img src={os} />
									</div>


								</div>) : null}

							<div class="headers">

								<div class="logoDiv"><img src={logo} /></div>

								<div class="h1">

									<div class="connect">
										<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}>Story</Link></div>
										<div ><Link activeClass="" id="fontSize" to="rm" spy={true} smooth={true} duration={550}>Roadmap</Link></div>
										<div ><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>
										<div ><Link activeClass="" id="fontSize" to="team" spy={true} smooth={true} duration={550}>Team</Link></div>
									</div>

									<div class="right">

										<div class="socialIcon">
											<img src={twitter} />
											<img src={os} />
										</div>

									</div>

								</div>

							</div>
							<div class="introductionSub">

								<div class="intro">
									<img src={logoImg} />
								</div>

								<div class="intro2">
									<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing.</p>
									<Link activeClass="" to="mint" spy={true} smooth={true} duration={550}><button className='button-56'>Mint Now</button></Link>
								</div>


							</div>

							<div className='carouselMain'>
								<div class="boxWrapC">

									<div class="slider">
										<div class="slide-track2">
											<div class="slide">
												<img id="img1" class="carouselIMG" src={d1} alt="d5" />
											</div>
											<div class="slide">
												<img id="img2" class="carouselIMG" src={d2} alt="d5" />
											</div>
											<div class="slide">
												<img id="img3" class="carouselIMG" src={d3} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d4} alt="d4" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d5} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d6} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d7} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d8} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d9} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d10} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d11} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d12} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d13} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d14} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d15} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d16} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d17} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d18} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d19} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d20} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d21} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d22} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d23} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d24} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d25} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d26} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d27} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d28} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d29} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d30} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d31} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d32} alt="d5" />
											</div><div class="slide">
												<img class="carouselIMG" src={d33} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d34} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d35} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d36} alt="d5" />
											</div><div class="slide">
												<img class="carouselIMG" src={d37} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d38} alt="d5" />
											</div><div class="slide">
												<img class="carouselIMG" src={d39} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d40} alt="d5" />
											</div><div class="slide">
												<img class="carouselIMG" src={d41} alt="d5" />
											</div>
											<div class="slide">
												<img class="carouselIMG" src={d42} alt="d5" />
											</div><div class="slide">
												<img class="carouselIMG" src={d43} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d44} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d45} alt="d5" />
											</div>

											<div class="slide">
												<img class="carouselIMG" src={d46} alt="d5" />
											</div>
										</div>
									</div>
								</div>
							</div>

							<img className='vault' src={vault} />

							<Ticker />
						</div>
					</Element>


				</div>

				<Element>
					<Carousel />
				</Element>

				<Element name="story">
					<Story />
				</Element>

				<Element name="rm">
					<Roadmap />
				</Element>

				<Banners />

				<Element name="faq">
					<FAQ />
				</Element>

				<Element name="mint">
					<div className='mintSection'>
						<div className='titleMint'>The rare Pepe and Friends Collection</div>
						<div className='btn2Mint'>
							<button className='lineBtn' onClick={lineArtDapp}>Pepe Line ART</button>
							<button className='colorBtn' onClick={colorArtDapp}>Pepe Color ART</button>
						</div>
					</div>
				</Element>

				<Element name="team">
					<Team />
				</Element>

				<Footer />


			</div >

			{showButton ?
				<Link to="up" id="sticky-button" spy={true} smooth={true} duration={550}><div className='stickyMain'><img class="stickyBtn2" src={up} /></div></Link>
				: null}
		</div >
	)

}
export default App;

import React, { Component } from 'react';
import cup from '../assets/cup.png';
import glass from '../assets/glass.png';

class Story extends Component {

    render() {
        return (

            <div className='storyMain'>
                <div className='title'>Our Story</div>
                <p className='contents'>Welcome to <span>Peyote Pepe</span>, an extraordinary NFT collection that combines the charm of whimsical storytelling with the vibrant world of digital art. This collection is a celebration of creativity, imagination, and cultural heritage, embodied by a unique character named Pepe.</p>
                <p className='contents'>Peyote Pepe invites you into a realm where <span>tradition meets modernity</span>, and every piece of art tells a tale of <span>magic and wonder</span>. Inspired by the rich heritage of his ancestors and the mystical power of the peyote hat, Pepe's adventures come to life in vivid, captivating detail. Each NFT in this collection is a <span>unique masterpiece</span>, offering collectors a chance to own a piece of this enchanting universe.</p>
                <div className='imgs2'>
                    <img className='cup' src={cup} />
                    <img className='glass' src={glass} />
                </div>
            </div>

        );
    }
}

export default Story;        
import React, { Component } from 'react';

class Ticker extends Component {


    render() {

        return (

          <div id='tickerMain2'>
            <div class="ticker-wrap">
            <div class="ticker">
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
              <div class="ticker__item">Peyote PEPE 🐸</div>
            </div>
            </div>
            </div>)
    }
}

export default Ticker;